import { useState, useEffect } from 'react';
import './file_browser.css';
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Storage } from 'aws-amplify';
import FileItem from './file_item';

export default FileBrowser;

function FileBrowser() {
  const [loading, setLoading] = useState(false);
  const [listFiles, setListFiles] = useState([]);

  async function getFileList() {
    const result = await Storage.list('', { level: 'private' });
    setListFiles(result);
  }

  useEffect(() => {
    getFileList();
  }, []);

  async function handleDelete(filename) {
    if (window.confirm(`Supprimer le ficher ${filename} ?`)) {
      const resp = await Storage.remove(filename, { level: 'private' });
      console.log(resp);
      getFileList();
    }
  }

  const handleChange = async (e) => {
    const file = e.target.files[0];
    console.log("FILE", file.name);
    try {
      setLoading(true);
      // Upload the file to s3 with private access level. 
      await Storage.put(file.name, file, {
        level: 'private',
        contentType: 'application/data',
        serverSideEncryption: 'AES256'
      });
      setLoading(false);
      getFileList();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div >
      <div id="header">
        <div id="file">
        Ajouter un fichier: &nbsp;
               {loading ? <h3>Uploading...</h3> : <input
          type="file"
          onChange={(evt) => handleChange(evt)}
        />}
        </div>
        <div id="signout">
          <AmplifySignOut />
        </div>
      </div>


      <div className="fileContainer" >
        {listFiles.map(file => (
          <FileItem key={file.key} file={file} handleDelete={handleDelete}></FileItem>
        ))}
      </div>
    </div>
  );
}