/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b568acd2-cefd-42d8-94c8-179d32900efa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ndj1m11le",
    "aws_user_pools_web_client_id": "2m08ihijd239puctk6msf5un08",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_user_files_s3_bucket": "securefilestorage203207-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
