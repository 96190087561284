import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import './file_item.css';


export default FileItem;

function FileItem({file, handleDelete}) {
    const [url, setUrl] = useState('#');

    async function getUrl() {
        const filename = file.key;
        const url = await Storage.get(filename, { level: 'private', expires: 300 });
        setUrl(url);
    }
    useEffect(()=>{
        getUrl();
    }, []);



    return (
        <div className="fileItem" >
            <div className="title">{file.key}</div>
            <div><img src={url} alt="" width="120px" /> </div>
            <div><button onClick={()=>handleDelete(file.key)}>Supprimer</button></div>
            <div><a href={url}>Telecharger</a></div>

        </div>
    )
}