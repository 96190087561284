import React, { useState, useEffect } from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import FileBrowser from './components/file_browser';

import awsconfig from './aws-exports';

import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

I18n.putVocabulariesForLanguage("en-US", {
  [Translations.SIGN_IN_HEADER_TEXT]: "Se Connecter",
  [Translations.SIGN_IN_ACTION]: "Connection",
  [Translations.USERNAME_LABEL]: 'Identifiant *',
  [Translations.USERNAME_PLACEHOLDER]: 'Identifiant',
  [Translations.PASSWORD_LABEL]: 'Mot de passe *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Mot de passe',
  [Translations.EMPTY_USERNAME]: 'L\'identifiant ne peut etre vide',
  [Translations.EMPTY_PASSWORD]: 'Le mot de passe ne peut etre vide',
  [Translations.INVALID_USERNAME]: 'Identifiant ou mot de passe incorrect.',
  [Translations.SIGN_OUT]: 'Deconnection',
  [Translations.INVALID_USERNAME]: 'Identifiant non valid.',
  [Translations.CONFIRM_SMS_CODE]: 'Confirmation de votre code',
  [Translations.CODE_LABEL]: 'verification du code',
  [Translations.CODE_PLACEHOLDER]: 'entrez le code',
  [Translations.CONFIRM]: 'Confirmer',
  [Translations.BACK_TO_SIGN_IN]: 'retour connection'
});

Amplify.configure(awsconfig);

const App = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <FileBrowser />
    </div>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn 
        slot="sign-in"
        hideSignUp={true}
        formFields={[{ type: "username" },{type: "password", hint: ""}]}
      >
      </AmplifySignIn>
    </AmplifyAuthenticator>
  );
}
export default App;